import axios from "axios";
import { API_URL } from "utils/constants";

const api = axios.create({
   baseURL: API_URL,
   headers: {
      'access-token': `${window.localStorage.getItem('access_token')}`,
      'Accept-Version': 1,
      'Accept': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json; charset=utf-8',
   }
});

export default api;