import { useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import { ThemeProvider } from '@mui/material/styles';

import { theme } from 'theme/theme'
import { SnackbarProvider } from "context/SnackbarContext";
import { LoadingProvider } from "context/LoadingContext";
import { UserProvider } from "context/UserContext";

import RequireAuth from "components/auth/RequireAuth"

import Home from "views/Home/HomePage/HomePage";
import Login from "views/Login/LoginPage/LoginPage";
import Game from "views/Game/GamePage/GamePage";
import Redeem from "views/Redeem/RedeemPage/RedeemPage";
import RedeemHistory from "views/RedeemHistory/RedeemHistoryPage/RedeemHistoryPage";
import Profile from "views/Profile/ProfilePage/ProfilePage"
import PageNotFound from "views/PageNotFound"

import {
  CBN_AGENT,
  CBN_URL,
  DENSTV_AGENT,
  DENSTV_URL
} from "utils/constants";

import './App.css';

function App() {
  const urlParams = new URLSearchParams(window.location.search);
  const agentRef = urlParams?.get('agentRef');

  useEffect(() => {
    const allUrlParams = window.location.href?.split("?")?.[1];
    const scheme = process.env.NODE_ENV === "development" ? "http://" : "https://";

    if (CBN_AGENT.includes(agentRef) && window.location.host !== CBN_URL) {
      window.location.replace(scheme + CBN_URL + "?" + allUrlParams)
      return;
    } else if (DENSTV_AGENT.includes(agentRef) && window.location.host !== DENSTV_URL) {
      window.location.replace(scheme + DENSTV_URL + "?" + allUrlParams)
      return;
    } else {
      return;
    }
  }, [agentRef])

  return (
    <>
      <div className='container'>
        <ThemeProvider theme={theme}>
          <UserProvider>
            <LoadingProvider>
              <SnackbarProvider>
                <Routes>
                  <Route element={<RequireAuth />}>
                    <Route path="/" element={<Home />} />
                    <Route path="/profile" element={<Profile />} />
                    <Route path="/redeem-history" element={<RedeemHistory />} />
                    <Route path="/game/:id" element={<Game />} />
                    <Route path="/redeem/:id" element={<Redeem />} />
                  </Route>
                  <Route path="/login" element={<Login />} />
                  <Route path="/login/test" element={<Login />} />
                  <Route path="*" element={<PageNotFound />} />
                </Routes>
              </SnackbarProvider>
            </LoadingProvider>
          </UserProvider>
        </ThemeProvider>
      </div>
    </>
  );
}

export default App;