import React from 'react'
import { useNavigate } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form'
import { Button, TextField, InputLabel, Box } from '@mui/material';
import api from 'services/axios';

import { useSnackbar } from 'context/SnackbarContext'

function LoginFormCBN() {
  
    const openSnackbar = useSnackbar()
    const navigate = useNavigate()

    let queryParams = new URLSearchParams(window.location.search);
    const emailParams = queryParams.get('email')

    const {
        control,
        handleSubmit,
        formState: {
            errors,
            isValid,
            isSubmitting
        }
    } = useForm({
        mode: 'onChange',
        defaultValues: {
            email: process.env.REACT_APP_EMAIL_ADDRESS || emailParams || '',
            password: process.env.REACT_APP_PASSWORD || '',
        }
    })

    const formOptions = {
        email: {
            required: "Email is required",
            maxLength: {
                value: 255,
                message: "Can't be longer than 255 characters"
            },
        },
        password: {
            required: "Password is required",
            maxLength: {
                value: 255,
                message: "Can't be longer than 255 characters"
            },
        },
    }

    const onSubmit = async (data) => {
      try {
        let responseData = {}
        if (window.location.pathname == '/login/test') {
          //  // For testing purpose
          //  responseData = await api.post('/api/user/poiuytr/login', {
          //     email: formData && formData.email,
          //     code: otpValue,
          //     channel_reference: 'LINKZH7GD',
          //     agent_reference: 'LINKKDN8K'
          //  })
        } else {
           // For production / staging purpose
           responseData = await api.post('/api/user/cbn/userpass/login', {
              email: data && data.email,
              password: data && data.password
           })
        }
        const {
           data: {
              access_token,
              is_admin,
              role,
              channel_reference,
              agent_reference
           }
        } = responseData

        const userAccount = {
           emailAddress: data?.email || '',
           accessToken: access_token || '',
           channelReference: channel_reference || '',
           agentReference: agent_reference || '',
           isAdmin: is_admin || false,
           role: role || '',
        }

        window.localStorage.setItem('userAccount', JSON.stringify(userAccount))
        window.localStorage.setItem('user_email', userAccount.emailAddress)
        window.localStorage.setItem('access_token', access_token)
        window.localStorage.setItem('channel_reference', channel_reference)
        window.localStorage.setItem('agent_reference', agent_reference)
        openSnackbar('Login success!', 'success');
        navigate('/');
     } catch (error) {
        if (
           error &&
           error.response &&
           error.response.data &&
           error.response.data.message
        ) {
           openSnackbar(error.response.data.message, 'error')
           if (error.response.data.message == 'unauthorized') {
              window.localStorage.removeItem('user_email');
           }
        }
     }
    }

    return (
        <Box sx={{
            padding: '0 20px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            height: '40vh'
        }}>
            <form
                onSubmit={handleSubmit(onSubmit)}
            >
                <Controller
                    control={control}
                    name="email"
                    rules={formOptions.email}
                    render={({ field: { onChange, value } }) => {
                        return (
                            <>
                              <div>
                                <InputLabel htmlFor="bootstrap-input">
                                    Email Address
                                </InputLabel>
                                <TextField
                                    fullWidth
                                    autoComplete='off'
                                    onChange={onChange}
                                    value={value}
                                    disabled={emailParams}
                                    error={errors.email}
                                    type="email"
                                    variant="filled"
                                    placeholder="e.g: john@example.com"
                                    InputProps={{
                                        disableUnderline: true,
                                    }}
                                />
                              </div>
                              <small style={{ userSelect: 'none', color: '#FFBA52' }}>
                                  {errors?.email && (
                                      <>
                                          {errors.email.message}
                                          <br />
                                      </>
                                  )}
                              </small>
                            </>
                        )
                    }}
                />
                <Controller
                    control={control}
                    name="password"
                    rules={formOptions.password}
                    render={({ field: { onChange, value } }) => {
                        return (
                            <>
                              <div style={{ marginTop: '2vh' }}>
                                <InputLabel htmlFor="bootstrap-input">
                                    Password
                                </InputLabel>
                                <TextField
                                    fullWidth
                                    autoComplete='off'
                                    onChange={onChange}
                                    value={value}
                                    error={errors.password}
                                    type="password"
                                    variant="filled"
                                    InputProps={{
                                        disableUnderline: true,
                                    }}
                                    
                                />
                              </div>
                              <small style={{ userSelect: 'none', color: '#FFBA52' }}>
                                  {errors?.password && (
                                      <>
                                          {errors.password.message}
                                          <br />
                                      </>
                                  )}
                              </small>
                            </>
                        )
                    }}
                />

                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Button
                        size="small"
                        variant="contained"
                        type="submit"
                        disabled={!isValid || isSubmitting}
                        sx={{
                            m: 'auto',
                            mt: '5vh',
                            width: 210,
                            padding: '8px',
                            color: 'white',
                            background: '#000000',
                            fontWeight: 'bold',
                            borderRadius: '15px',
                            '&:hover': {
                                filter: 'brightness(0.75)',
                            },
                            '&:disabled': {
                                background: '#FFEFC7',
                            }
                        }}
                    >
                        {
                          isSubmitting ?
                              'Loading'
                              :
                              'Sign in'
                        }
                    </Button>
                </Box>
                <div style={{ paddingTop: '3vh', textAlign: 'center' }}>
                  Need support? <b><a href='tel:62211500780' style={{color: 'white'}}>Contact us</a></b>
                </div>
            </form>
        </Box>
    )
}

export default LoginFormCBN