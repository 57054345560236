import React from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import ContentCards from 'views/Home/HomeMain/ContentCards/ContentCards'
import QuestionMarkBubbleIcon from 'assets/QuestionMarkBubbleIcon'

function HomeMain({
   games
}) {
   let anyHotItems = games.filter((item) => item.is_hot_item)
   return (
      <Box
         sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            gap: '26px'
         }}
      >
         {
            anyHotItems && anyHotItems.length > 0 && (
               <>
                  <Typography align='left' variant='h5' sx={{ fontSize: 22, fontWeight: 700, color: '#5D2FE1' }}>Hot Items</Typography>
                  <ContentCards
                     games={games.filter((item) => item.is_hot_item)}
                  />
               </>
            )
         }
         <Typography align='left' variant='h5' sx={{ fontSize: 22, fontWeight: 700, color: '#5D2FE1' }}>All Games</Typography>
         <ContentCards
            games={games.filter((item) => !item.is_hot_item)}
         />
         {/* <Box
           sx={{
              display: 'flex',
              gap: '10px',
              p: '10px 20px',
              background: '#E3D9FF',
              alignItems: 'center',
              borderRadius: '10px'
           }}
        >
           <QuestionMarkBubbleIcon />
           <Typography variant='body2'>How to get vouchers</Typography>
        </Box> */}
      </Box>
   )
}

export default HomeMain