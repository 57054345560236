export const CBN_AGENT = ["GAMEBOYWF", "GAMELTW47"];
export const DENSTV_AGENT = ["GAMEXCEFJ", "DENTGC21W"];

export const CBN_URL = window.location.href.includes("supreme-game")
    ? 'cbn.supreme-game.com'
    : window.location.href.includes("cloudxier")
        ? 'cbn-vms.cloudxier.com'
        : 'localhost:3001';

export const DENSTV_URL = window.location.href.includes("supreme-game")
    ? 'denstv.supreme-game.com'
    : window.location.href.includes("cloudxier")
        ? 'denstv-vms.cloudxier.com'
        : 'localhost:3001';

export const API_URL = 
  window.location.href.includes("supreme-game") ?
    'https://api-denstv.cloudxier.com'
  :
    window.location.href.includes('cloudxier') ?
      'https://api-denstv-vms-stg.cloudxier.com'
        :
      'http://localhost:3789'