import React from 'react'
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'

import LoadingPage from '../../components/loading/LoadingPage'

function PageNotFound() {
   const navigate = useNavigate();

   const urlParams = window.location.pathname && window.location.pathname.slice(1, window.location.pathname.length)
   const dataParams =
      urlParams &&
      urlParams.split('').slice(0, 4).join('') &&
      urlParams.split('').slice(0, 4).join('') == 'data' &&
      urlParams.split('').slice(5, urlParams.length).join('')

   if (dataParams) {
      return <LoadingPage dataParams={dataParams} />
   } else {
      return (
         <Box
            sx={{
               minHeight: '100vh',
               display: 'flex',
               flexDirection: 'column',
               justifyContent: 'center',
               alignItems: 'center',
               gap: '20px',
            }}
         >
            <Box
               sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
               }}
            >
               <Typography variant="h3">404</Typography>
               <Typography variant="h4">Page Not Found</Typography>
            </Box>
            <Button
               onClick={() => navigate('/')}
               variant="outlined"
               sx={{ borderRadius: '40px' }}
            >
               Back To Home
            </Button>
         </Box>
      )
   }
}

export default PageNotFound
