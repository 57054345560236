import React, { useEffect, useState } from 'react'
import { useLocation, Navigate, Outlet } from 'react-router-dom';
import api from 'services/axios';
import { useSnackbar } from 'context/SnackbarContext'

import LoadingPage from 'components/loading/LoadingPage'

function RequireAuth() {

   let queryParams = new URLSearchParams(window.location.search);

   const location = useLocation();
   const openSnackbar = useSnackbar()

   const userEmail = window.localStorage.getItem('user_email');
   const accessToken = window.localStorage.getItem('access_token')

   const userToken = window.localStorage.getItem('user_token')
   const userChannel = window.localStorage.getItem('user_channel')

   const emailParams = queryParams.get('userEmail') || userEmail
   const tokenParams = queryParams.get('userToken') || userToken
   const channelParamas = queryParams.get('channelRef') || userChannel
   const appTypeParams = queryParams.get('apptype')

   const [isLoggedIn, setIsLoggedIn] = useState(null)

   const checkSession = async (accessToken) => {
      try {
         const res = await api.post('/api/user/session/pub', {
            token: `${accessToken}`
         }, {
            headers: {
               'access-token': `${accessToken}`,
            }
         })
         const response = res && res.data
         if (response && response.email) {
            setIsLoggedIn(true)
         }
      } catch (error) {
         const errorData = error && error.response && error.response.data
         openSnackbar(error?.response?.data?.message?.toString().replace('-', ' '), 'error')
         if (errorData && errorData.message == 'invalid-credentials') {
            window.localStorage.removeItem('access_token')
            window.localStorage.removeItem('agent_reference')
            window.localStorage.removeItem('user_email')
            window.localStorage.removeItem('userAccount')
            window.location.reload()
         }
      }
   }

   const authenticateUser = async () => {
      try {
         const { data: response } = await api.post('/api/user/denstv/login', {
            email: emailParams,
            userToken: tokenParams,
            channel_reference: channelParamas,
            app_type: appTypeParams
         })
         if (response && response.success && response.access_token) {
            const userAccount = {
               emailAddress: emailParams || '',
               accessToken: response.access_token || '',
               channelReference: response.channel_reference || '',
               agentReference: response.agent_reference || '',
               isAdmin: false,
               role: '',
            }
            window.localStorage.setItem('user_email', emailParams)
            window.localStorage.setItem('userAccount', JSON.stringify(userAccount))
            window.localStorage.setItem('access_token', response.access_token)
            window.localStorage.setItem('channel_reference', response.channel_reference)
            window.localStorage.setItem('agent_reference', response.agent_reference)
            setIsLoggedIn(true)
         }
      } catch (error) {
         console.log(error)
         let errorMessage = error.response.data.message.toString().replace('-', ' ')
         openSnackbar(errorMessage, 'error')
         if (errorMessage == 'please relogin' || errorMessage == 'unauthorized' || errorMessage == 'invalid agent-identifier') {
            setIsLoggedIn(false)
         }
      }
   }

   useEffect(() => {
      if (!userEmail || !accessToken) {
         authenticateUser()
      } else {
         checkSession(accessToken)
      }
   }, [])

   switch (isLoggedIn) {
      case null:
         return <LoadingPage />
      case true:
         return <Outlet />
      case false:
         return <Navigate to={emailParams ? `/login?email=${emailParams}` : '/login'} replace state={{ from: location }} />
   }

}

export default RequireAuth