import React, { useState, useEffect } from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

import { useSnackbar } from 'context/SnackbarContext'
import { useUser } from 'context/UserContext'
import MainContainer from 'components/layout/container/MainContainer'
import RedeemHistoryCard from 'views/RedeemHistory/RedeemHistoryCard/RedeemHistoryCard'

import api from 'services/axios'

function RedeemHistory() {
   const openSnackbar = useSnackbar();
   const user = useUser();

   const [redeemHistory, setRedeemHistory] = useState([])

   const getRedeemHistory = async (accessToken) => {
      try {
         const res = await api.get('/api/clm/cust', {
            header: {
               'access-token': `${accessToken}`,
            }
         })
         setRedeemHistory(res.data.result);
      } catch (error) {
         openSnackbar(error.response.data.message.replace("-", " "), 'error');
      }
   }
   useEffect(() => {
      const accessToken = window.localStorage.getItem('access_token')
      if (accessToken && !user?.isAdmin) {
         getRedeemHistory(accessToken)
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [user?.isAdmin])

   return (
      <MainContainer>
         <Box>
            <Typography variant="h5" sx={{ fontSize: 30, fontWeight: 'bold', mb: '35px' }}>Redeem History</Typography>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '20px', m: '0 -40px' }}>
               {redeemHistory && redeemHistory.length > 0 && redeemHistory.map((item, i) => (
                  <RedeemHistoryCard
                     key={i}
                     transactionId={item.claim_reference}
                     gameType={item.vouchers[0].voucher.game.name}
                     date={item.createdAt}
                     validUntil={item.vouchers[0].voucher.expired_date}
                     status={item.status || 'success'}
                  />
               ))}
            </Box>
         </Box>
      </MainContainer >
   )
}

export default RedeemHistory