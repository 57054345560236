import React from 'react'
import Lottie from "lottie-react";
import { styled } from '@mui/material/styles';

import MuiBox from '@mui/material/Box'
import Typography from '@mui/material/Typography'

import loading from "assets/animation/99833-edupia-loading.json";

const Box = styled((props) => (
   <MuiBox
      {...props}
   />
))(({ theme }) => ({
   backgroundColor: '#5D2FE1',
   minHeight: '100vh',
   height: '100%',
   paddingTop: '80px'
}));

function LoadingPage() {
   return (
      <Box>
         <Lottie
            animationData={loading}
            loop={true}
            autoPlay={true}
         />
         <MuiBox sx={{ textAlign: 'center', color: 'white', marginTop: '-60px' }}>
            <Typography variant='h5' sx={{ fontSize: 22, fontWeight: 'bold' }}>Loading</Typography>
            <Typography variant='body1' sx={{ fontSize: 15 }}>We are processing your request...</Typography>
         </MuiBox>
      </Box>
   )
}

export default LoadingPage