import React, { useState, useEffect } from 'react'

import Box from '@mui/material/Box';
import CBNLogo from '../../../assets/cbn-fiber-darkBG-1.png';
import LoginFormCBN from '../LoginForm/LoginFormCBN';

import CBNBackgroundImage from '../../../assets/gamers-code-bg.jpg'

function Login() {

   const [isStepOneSuccess, setIsStepOneSuccess] = useState(false)
   const [formData, setFormData] = useState('')

   const handleSuccess = (data) => {
      if (data) {
         window.localStorage.setItem('user_email', data.email)
         setFormData(data)
      }
      setIsStepOneSuccess(true)
      window.localStorage.setItem('LOGIN_STEP_ONE_SUCCESS', 'true')
   }

   useEffect(() => {
      const loginStepOneSuccess = window.localStorage.getItem('LOGIN_STEP_ONE_SUCCESS');
      loginStepOneSuccess === 'true' ? setIsStepOneSuccess(true) : setIsStepOneSuccess(false)
   }, [])

   return (
      <>
         <img 
          src={CBNBackgroundImage} 
          style={{
            position: 'absolute',
            width: '100%',
            height: '100vh',
            objectFit: 'cover',
            zIndex: 0,
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            overflow: 'hidden'
          }} />
        <Box
         sx={{
            background: '#5D2FE1',
            color: 'white',
            height: '100%'
         }}
        >
          <Box
              container
              direction="column"
              justifyContent="flex-start"
              alignItems="stretch"
              sx={{
                height: '100vh',
                label: {
                    color: 'white',
                    mb: '5px',
                },
                '& .MuiTextField-root': {
                    background: 'white',
                    borderRadius: '10px',
                },
                '& .MuiInputBase-root': {
                    background: 'transparent',
                },
                '& .MuiGrid-item': {
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                },
                position: 'relative'
              }}
           >
              <Box sx={{ alignItems: 'center', padding: '30px 0' }}>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column'}}>
                    <div style={{ paddingTop: '1em', width: '180px', height: 'auto', paddingBottom: '3em' }}>
                      <img src={CBNLogo} style={{ width: '100%', height: '100%', objectFit: 'contain'}} />
                    </div>
                    <LoginFormCBN />
                </Box>
              </Box>
          </Box>
        </Box>
      </>
   )
}

export default Login